import React from 'react'
import Layout from "../../components/layout"
import BackButton from '../../components/BackBtn'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

export default function landschappen({data}) {

    return (
        <Layout>
            <main className="main-content">
                <div className="title-container">
                   <h1 className="main-title">Landschappen</h1>
                   <BackButton path="../" text="Terug" />
                </div>
                <p className="main-description">
                    Het is een kunst om en landschap zo te fotograferen, dat je het landschap ook ‘beleefd’. Er zijn zoveel
                    landschappen die nog in beeld te brengen zijn. En zo velen gingen mij voor.
                </p>
                <div className="photo-carousel">
                    <Carousel>
                    {data.allFile.nodes.map(image => {
                        return (
                            <Carousel.Item key={image.id} interval={5000} >
                                <GatsbyImage 
                                    image={image.childImageSharp.gatsbyImageData} 
                                    alt={image.base.split('-').join(' ').split('.')[0]}
                                />
                                <Carousel.Caption>
                                    <p className="banner-text">{image.base.split('-').join(' ').split('.')[0]}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                        })}
                    </Carousel>
                </div>
            </main>
        </Layout>
    )
}

export const getImages = graphql`
query {
    allFile(filter: {relativeDirectory: {eq: "landschappen"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
        base
        id
      }
    }
  }
`


